<template>
	<v-menu v-model="menu" :close-on-content-click="false" full-width lazy min-width="290px" offset-y transition="scale-transition" @input="onMenuSwitchState">
		<template v-slot:activator="{ on }">
			<v-text-field v-model="dateString" :label="label" prepend-inner-icon="event" readonly v-on="on"></v-text-field>
		</template>
		<v-date-picker
			ref="picker"
			v-model="date"
			color="primary"
			type="month"
			:min="oldestDate"
			:max="youngestDate"
			no-title
			reactive
			@input="onInput"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'MonthPicker',
	props: {
		label: {
			required: false,
			type: String,
			default: () => ''
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			date: null,
			dateString: '',
			menu: false
		}
	},
	computed: {
		oldestDate: function () {
			const dateNow = new Date()
			if (this.value.year) {
				dateNow.setFullYear(this.value.year - 20)
			} else {
				dateNow.setFullYear(dateNow.getFullYear() - 20)
			}
			return dateNow.toISOString().substring(0, 10)
		},
		youngestDate: function () {
			const dateNow = new Date()
			if (this.value.year) {
				dateNow.setFullYear(this.value.year + 20)
			} else {
				dateNow.setFullYear(dateNow.getFullYear() + 20)
			}
			return dateNow.toISOString().substring(0, 10)
		}
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (!newVal) {
					const currentDate = new Date()
					this.date = currentDate.toISOString().substring(0, 7)
					this.onInput()
				} else if (!this.date || newVal.month || newVal.year) {
					const date = new Date()
					date.setMonth(newVal.month - 1)
					date.setFullYear(newVal.year)
					this.dateString = this.$t(`documents.folder_creation.months_name.${newVal.month}`) + ' ' + newVal.year
					this.date = date.toISOString().substring(0, 7)
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		onInput: function () {
			const date = new Date(this.date)
			const month = date.getMonth() + 1
			const year = date.getFullYear()
			const dateInfo = {
				year: year,
				month: month
			}
			this.setValue(dateInfo)
			this.date = date.toISOString().substring(0, 7)
			this.menu = false
		},
		onMenuSwitchState: function () {
			if (this.menu) {
				this.$nextTick(() => {
					this.$refs.picker.activePicker = 'MONTH'
				})
			}
		},
		setValue: function (date) {
			this.$emit('changeDate', date, 'month')
		}
	}
}
</script>
